<template>
  <span class="beta-tag">
    <CyTooltip v-bind="{ [direction]: true }">
      <template #activator="{ on }">
        <div
          class="d-flex"
          v-on="on">
          <v-chip
            x-small
            class="beta-tag__chip mr-1">
            {{ _.upperFirst($t('untranslated.beta')) }}
          </v-chip>
          <v-icon size="16">
            help_outline
          </v-icon>
        </div>
      </template>
      <span v-html="$sanitizeHtml(tooltipText || $t('defaultTooltipText'))"/>
    </CyTooltip>
  </span>
</template>

<script>
export default {
  name: 'CyBetaTag',
  props: {
    tooltipText: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      default: 'top',
    },
  },
  i18n: {
    messages: {
      en: {
        defaultTooltipText: 'This feature is still in beta, you may encounter unexpected behavior.',
      },
      es: {
        defaultTooltipText: 'Esta feature aún está en beta, puede encontrarse algún comportamiento inesperado.',
      },
      fr: {
        defaultTooltipText: 'Cette fonctionalité est encore en beta, vous rencontrerez peut-être des comportements inattendus.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .beta-tag {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;

    &__chip {
      background-color: get-color("secondary", "light-2") !important;
      color: get-color("secondary", "dark-2");
    }
  }

  .v-icon {
    color: get-color("grey", "dark-1");
  }
</style>
